import React from 'react';
import { graphql } from 'gatsby';
import { render } from 'datocms-structured-text-to-plain-text';
import { StructuredTextDocument } from 'react-datocms';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import Typography from '../../../primitives/typography';
import Container from '../../../primitives/grid/container';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import DatoAsset from '../../../primitives/asset';
import RowSet from '../../../primitives/grid/row-set';
import ColSet from '../../../primitives/grid/col-set';
import { colors } from '../../../primitives/colors';
import DownloadIcon from './download.svg';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';

export type DownloadableListGridProps = Queries.DownloadableListGridPropsFragment;

const DownloadableListGrid = ({
    lists,
}: DownloadableListGridProps): JSX.Element => (
    <Container css={{
        marginTop: '110px',
        marginBottom: '110px',
        [breakpoints.mb]: {
            marginTop: '40px',
            marginBottom: '40px',
        },
    }}
    >
        <Row css={{
            rowGap: '48px',
            [breakpoints.tb]: {
                rowGap: '32px',
            },
            [breakpoints.mb]: {
                rowGap: '16px',
            },
        }}
        >
            {lists.map(({
                title,
                richText,
                optionalPreviewImage,
                files,
            }) => {
                const hasRichText = !!render(richText);

                return (
                    <Col breakpoints={{
                        dt: {
                            span: (() => {
                                if (lists.length < 2) {
                                    if (optionalPreviewImage && hasRichText) {
                                        return 6;
                                    }
                                    if (hasRichText) {
                                        return 8;
                                    }
                                }
                                return 4;
                            })(),
                        },
                        tb: { span: lists.length < 2 ? 12 : 6 },
                    }}
                    >
                        <RowSet
                            breakpoints={{
                                dt: { between: 16 },
                                mb: { between: 10 },
                            }}
                            css={{ marginTop: 'unset' }}
                        >
                            <Typography
                                fontSize={(!optionalPreviewImage || (lists.length < 2 && hasRichText)) ? {
                                    dt: 4053,
                                    tb: 3234,
                                    mb: 2429,
                                } : {
                                    dt: 2429,
                                    tb: 1822,
                                    mb: 1627,
                                }}
                                fontWeight="light"
                            >
                                {title}
                            </Typography>
                            {richText && render(richText) && (
                                <Typography
                                    css={{
                                        opacity: 0.8,
                                        [breakpoints.tb]: {
                                            maxWidth: '700px',
                                        },
                                        [breakpoints.mb]: {
                                            paddingBottom: '24px',
                                        },
                                    }}
                                    fontSize={{
                                        dt: 1822,
                                        mb: 1420,
                                    }}
                                    override
                                >
                                    <CustomStructuredTextRenderer data={richText as unknown as StructuredTextDocument} />
                                </Typography>
                            )}
                            {(!(lists.length === 1 && optionalPreviewImage && hasRichText) || lists.length > 1) && (
                                <>
                                    {optionalPreviewImage && (
                                        <div css={{
                                            backgroundColor: colors.shadesWhite,
                                            borderRadius: borderRadius.medium,
                                            border: `1px solid ${colors.grey100}`,
                                            padding: '20px',
                                            maxWidth: '360px',
                                            [breakpoints.mb]: {
                                                maxWidth: '60%',
                                            },
                                        }}
                                        >
                                            <DatoAsset asset={optionalPreviewImage} />
                                        </div>
                                    )}
                                    {optionalPreviewImage ? (
                                        <ColSet breakpoints={{
                                            dt: { between: 12 },
                                        }}
                                        >
                                            {files?.map(file => (
                                                <a href={`/site-downloads${file?.file?.path || ''}`} download css={{ color: colors.primaryOrange, display: 'flex', gap: '12px', alignItems: 'start', justifyContent: 'flex-start' }}>
                                                    <DownloadIcon css={{ width: '16px', height: '16px', marginTop: '2px' }} />
                                                    {file?.title}
                                                </a>
                                            ))}
                                        </ColSet>
                                    ) : (
                                        <RowSet breakpoints={{
                                            dt: { between: 12 },
                                        }}
                                        >
                                            {files?.map(file => (
                                                <a href={`/site-downloads${file?.file?.path || ''}`} download css={{ color: colors.primaryOrange, display: 'flex', gap: '12px', alignItems: 'start', justifyContent: 'flex-start' }}>
                                                    <DownloadIcon css={{ width: '16px', height: '16px', marginTop: '2px' }} />
                                                    {file?.title}
                                                </a>
                                            ))}
                                        </RowSet>
                                    )}
                                </>
                            )}
                        </RowSet>
                    </Col>
                );
            })}
            {(lists.length === 1 && lists[0].optionalPreviewImage && render(lists[0].richText)) && (
                <Col
                    breakpoints={{
                        dt: { span: 6 },
                    }}
                    css={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        [breakpoints.tb]: {
                            alignItems: 'flex-start',
                        },
                    }}
                >
                    <div css={{
                        backgroundColor: colors.shadesWhite,
                        borderRadius: borderRadius.medium,
                        border: `1px solid ${colors.grey100}`,
                        padding: '20px',
                        maxWidth: '360px',
                        marginBottom: '12px',
                    }}
                    >
                        <DatoAsset asset={lists[0].optionalPreviewImage} />
                    </div>
                    <div
                        css={{ gap: '12px', display: 'inline-flex' }}
                    >
                        {lists[0].files?.map(file => (
                            <a href={`/site-downloads${file?.file?.path || ''}`} download css={{ color: colors.primaryOrange, display: 'flex', gap: '12px', alignItems: 'start', justifyContent: 'flex-start' }}>
                                <DownloadIcon css={{ width: '16px', height: '16px', marginTop: '2px' }} />
                                {file?.title}
                            </a>
                        ))}
                    </div>
                </Col>
            )}
        </Row>
    </Container>
);

export default DownloadableListGrid;

export const query = graphql`
    fragment DownloadableListGridProps on DatoCmsDownloadableListGrid {
        lists {
            title
            richText {
                blocks
                links
                value
            }
            optionalPreviewImage {
                ...DatoCmsInternalAsset
            }
            files {
                title
                file {
                    ...DatoCmsInternalAsset
                }
            }
        }
    }
`;
